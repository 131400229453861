function initializeSingleDatePicker(elementId, format, timeZone) {
  var input = $(`#${elementId}`)
  var datePicker = $(`#${elementId}-select`)
  datePicker.daterangepicker({
    singleDatePicker: true,
    locale: {format: format},
    autoUpdateInput: false,
    autoApply: true,
    startDate: input.val().length > 0 ? moment.tz(input.val(), timeZone) : now
  })

  // hack to start with empty date
  datePicker.on("apply.daterangepicker", function(ev, picker) {
    datePicker.find("span").html(toDate(picker.startDate).format("MMMM D, YYYY"))
    input.val(toDate(picker.startDate).utc().format())
    submitIfCompleted($(`#${elementId}`).closest("form"))
  })

  if (input.val().length > 0) {
    var picker = datePicker.data("daterangepicker")
    datePicker.find("span").html(toDate(picker.startDate).format("MMMM D, YYYY"))
  }
}

function dateStr(daysAgo) {
  return now.clone().subtract(daysAgo || 0, "days").format(format)
}

function initializeDateRangePicker(format, timeZone) {

  function setTimeInputs(start, end) {
    $("#start_time").val(toDate(start).utc().format())
    $("#end_time").val(toDate(end).endOf("day").utc().format())
  }

  var control = $("#reportrange").daterangepicker(
    {
      ranges: {
        "Today": [dateStr(), dateStr()],
        "Last 7 Days": [dateStr(6), dateStr()],
        "Last 30 Days": [dateStr(29), dateStr()]
      },
      locale: {
        format: format,
        cancelLabel: 'Clear'
      },
      autoUpdateInput: false,
      opens: "right",

      alwaysShowCalendars: true
    },
    function(start, end) {
      setTimeInputs(start, end)
      submitIfCompleted($("#start_time").closest("form"))
    }
  );

  control.on('apply.daterangepicker', function (ev, picker) {
    const startTimeString = $("#start_time").val()
    const endTimeString = $("#end_time").val()
    if (startTimeString && endTimeString) {
      const startTime = moment.tz(startTimeString, timeZone)
      const endTime = moment.tz(endTimeString, timeZone)
      $("#reportrange span").html(toDate(startTime).format("MM/DD/YYYY") + " - " + toDate(endTime).format("MM/DD/YYYY"))
    }
    else {
      $("#reportrange span").html('')
    }
  });

  control.on('cancel.daterangepicker', function (ev, picker) {
    $(this).val('');
    $("#start_time").val('');
    $("#end_time").val('');
    $("#reportrange span").html('')
  });

  if ($("#start_time").val().length > 0) {
    var picker = $("#reportrange").data("daterangepicker")
    picker.setStartDate(moment.tz($("#start_time").val(), timeZone))
    picker.setEndDate(moment.tz($("#end_time").val(), timeZone))
    $("#reportrange").trigger("apply.daterangepicker", picker)
  } else {
    var picker = $("#reportrange").data("daterangepicker")
    $("#reportrange").trigger("apply.daterangepicker", picker)
    submitIfCompleted($("#start_time").closest("form"))
  }
}

window.blazerExtensions = {
  initializeSingleDatePicker,
  initializeDateRangePicker
}
